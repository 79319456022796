
@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  font-display: swap;
  src: local('Pretendard Black'), url(./css/fonts/Pretendard-Black.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  font-display: swap;
  src: local('Pretendard ExtraBold'), url(./css/fonts/Pretendard-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-display: swap;
  src: local('Pretendard Bold'), url(./css/fonts/Pretendard-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-display: swap;
  src: local('Pretendard SemiBold'), url(./css/fonts/Pretendard-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  src: local('Pretendard Medium'), url(./css/fonts/Pretendard-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  src: local('Pretendard Regular'), url(./css/fonts/Pretendard-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  font-display: swap;
  src: local('Pretendard Light'), url(./css/fonts/Pretendard-Light.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  font-display: swap;
  src: local('Pretendard ExtraLight'), url(./css/fonts/Pretendard-ExtraLight.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  font-display: swap;
  src: local('Pretendard Thin'), url(./css/fonts/Pretendard-Thin.woff) format('woff');
}

* {
  font-family: 'Pretendard', sans-serif !important;
}

.chakra-select {
  color: #7F8589;
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important;
}

.chakra-select__icon-wrapper {
  right: 0px !important;
  color: #7F8589;
}

.main-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
}

.product-inquiry-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 453px;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.about-below-bg::before {
  content: '';
  position: absolute;
  bottom: 389px;
  left: 0;
  width: 100%;
  height: 402px;
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: none;
}

@media (max-width: 768px) {
  .about-below-bg::before {
    height: 622px;
    bottom: 624px;
  }
  .product-inquiry-bg::before {
    height: 270px;
  }
}

.search-bar {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
}

.search-bar.open {
  opacity: 1;
  visibility: visible;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

table > thead > tr > th {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #000 !important;
  text-align: center !important;
}

table > tbody > tr > td {
  font-size: 16px;
  font-weight: 400;
  text-align: center !important;
}

.td-notice {
  font-weight: 600 !important;
}

.td-title {
  font-size: 18px;
}

.chakra-checkbox__control {
  border: 1px solid !important;
  border-color: #004A8D !important;
  border-radius: 0 !important;
}